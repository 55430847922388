import { ResponseData, ResponseError, axiosInstance, Request } from '@/helpers'
const moduleName = 'mail'
export const GetAll = () =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}`,req).then(ResponseData).catch(ResponseError)
}
export const Read = (id) =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}/${id}`,req).then(ResponseData).catch(ResponseError)
}
export const Remove = ({id}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/remove`,{
    id
  },req).then(ResponseData).catch(ResponseError)
}
export const MarkRead = ({id}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/read`,{
    id
  },req).then(ResponseData).catch(ResponseError)
}
export default {
  GetAll,
  Remove,
  MarkRead,
  Read,
}