<template>
  <div>
    <v-card flat>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            md="3"
            lg="2"
            xl="2"
          >
            <v-checkbox
              v-model="OnlyNew"
              class="ma-2"
              color="green"
              label="Chưa đọc"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            lg="2"
            xl="2"
          >
            <v-checkbox
              v-model="ShowSpam"
              class="ma-2"
              color="blue"
              label="Hiện Spam"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            lg="3"
            xl="3"
          >
            <template  v-if="Selected.length > 0">
              <v-btn
                class="ma-2"
                color="green"
                @click="doMarkReadAll"
              >
                Mark All Read
              </v-btn> 
              <v-btn
                v-if="getUser.Access === 'admin'"
                class="ma-2"
                color="red"
                @click="doDeleteAll"
              >
                Delete All
              </v-btn> 
            </template>
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            md="6"
            lg="4"
            xl="4"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Tìm kiếm nhanh"
              single-line
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-model="Selected"
          :search="search"
          :headers="headers"
          :items="itemsWithIndex"
          :loading="loader"
          show-select
          :footer-props="{itemsPerPageText:'Dữ liệu mỗi trang','items-per-page-options':[50,100,150,200]}"
          :items-per-page="100"
        >
          <template #no-data>
            <br />
            <v-row>
              <v-col cols="12">
                <v-alert
                  border="right"
                  colored-border
                  type="warning"
                >
                  <span
                    class="orange--text text-center"
                  >
                    KHÔNG CÓ DỮ LIỆU
                  </span>
                </v-alert>
              </v-col>
            </v-row>
          </template>
          <template #[`item.controller`]="{ item }">
            <template v-if="item.controller !== undefined">
              <Controller
                :item="item"
                :controls="item.controller"
                :group="false"
              />
            </template>
          </template>
          <template #[`item.Subject`]="{ item }">
              <v-btn 
                  @click="doRead(item)"
              >
                <v-icon 
                  left
                  v-if="item.Read === false"
                >
                  mdi-alert-decagram
                </v-icon>
                  {{item.Subject}}
              </v-btn>
          </template>
          <template #[`item.Time`]="{ item }">
              {{ThoiGian(item.Time)}}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="Box.Read"
      max-width="900px"
      persistent
    >
      <Header
        :title="Reader.Subject"
        :close.sync="Box.Read"
      >
          <v-card-text>
              <v-list-item class="grow">
                <v-list-item-content>
                  <v-list-item-subtitle>From</v-list-item-subtitle>
                  <v-list-item-title>{{Reader.From}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="grow">
                <v-list-item-content>
                  <v-list-item-subtitle>To</v-list-item-subtitle>
                  <v-list-item-title>{{Reader.To}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="grow">
                <v-list-item-content>
                  <v-list-item-subtitle>Subject</v-list-item-subtitle>
                  <v-list-item-title>{{Reader.Subject}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-tabs
                fixed-tabs
                background-color="primary"
                dark
              >
                <v-tab
                  class="secondary--text"
                  v-if="isTeespringVerify"
                >
                  Teespring Verify
                </v-tab>
                <v-tab
                  class="secondary--text"
                  v-if="isTeespringPaypal"
                >
                  Teespring Paypal
                </v-tab>
                <v-tab
                  class="secondary--text"
                  v-if="isGmail"
                >
                  Gmail Code
                </v-tab>
                <v-tab
                  class="secondary--text"
                >
                  HTML
                </v-tab>
                <v-tab
                  class="secondary--text"
                >
                  Text
                </v-tab>
                <v-tab-item v-if="isTeespringVerify">
                  <v-responsive :aspect-ratio="16/9" max-height="400px" class="overflow-y-auto">
                    <v-textarea
                      :value="isTeespringVerify"
                      label="Link Verify Teespring"
                    />
                  </v-responsive>
                </v-tab-item>
                <v-tab-item v-if="isTeespringPaypal">
                  <v-responsive :aspect-ratio="16/9" max-height="400px" class="overflow-y-auto">
                    <v-textarea
                      :value="isTeespringPaypal"
                      label="Link Verify Paypal Teespring"
                    />
                  </v-responsive>
                </v-tab-item>
                <v-tab-item v-if="isGmail">
                  <v-responsive :aspect-ratio="16/9" max-height="400px" class="overflow-y-auto">
                    {{isGmail[0]}}
                  </v-responsive>
                </v-tab-item>
                <v-tab-item>
                  <v-responsive :aspect-ratio="16/9" max-height="600px" class="overflow-y-auto">
                    <iframe id="email_content" width="100%" height="600px;" />
                  </v-responsive>
                </v-tab-item>
                <v-tab-item>
                  <v-responsive :aspect-ratio="16/9" max-height="400px" class="overflow-y-auto">
                    {{Reader.TextContent}}
                  </v-responsive>
                </v-tab-item>
              </v-tabs>
	            <loading :status="loaderHtml" />
          </v-card-text>
      </Header>
    </v-dialog>
  </div>
</template>

<script>
import mailService from '@/modules/mail.js';
import { ThoiGian, GetStr } from '@/helpers'
import { Xoa } from '@/helpers/arrays.js';
import {mapGetters} from 'vuex'
import Header from '@/components/base/pagehead.vue'
import Controller from '@/components/base/controller.vue'
import loading from '@/components/base/loading.vue';
export default {
  components:{
    Header,
    Controller,
    loading,
  },
  props: ['total','loader',"emails"],
  data () {
    return {
      Selected: [],
      OnlyNew:true,
      ShowSpam:false,
      search:undefined,
      Box:{
        Read:false,
      },
      Reader:{
        Subject:undefined,
        From: undefined,
        To: undefined,
        HtmlContent:undefined,
        TextContent:undefined,
      },
      headers:[
        { text: 'Title', align: 'center', sortable: true,value: 'Subject',width:"50%",class:"primary secondary--text text-center" },
        { text: 'Email', align: 'center', sortable: true, value: 'To',width:"15%",class:"primary secondary--text text-center" },
        { text: 'Date', align: 'center', sortable: true, value: 'Time',width:"15%",class:"primary secondary--text text-center" },
        { text: 'Thao tác', align: 'center', sortable: false, value: 'controller',width:"20%",class:"primary secondary--text text-center" },
      ],
      autoLoader:undefined,
      loaderHtml:false,
    }
  },
  computed:{
    ...mapGetters(['getUser']),
    itemsWithIndex(){
      return this.emails.map(
        (item) => {
          let data = {};
          data = {
            ...item,
            controller:this.controller
          }

          if(this.search) return data;
          let showEmail = false;
          if(this.ShowSpam === true){
            showEmail = true;
            
          }else{ 
            if(item.Spam === false){
              showEmail = true;
            }else{
              showEmail = false;
            }
          }
          if(showEmail){
            if(this.OnlyNew && item.Read === false){
              return data;
            }
            if(this.OnlyNew === false) return data;
          }
        }).filter(v=>!!v)
    },
    controller(){
      if(this.getUser.Access === 'admin'){
        return [
          {t:'Xóa',c:'red',a:this.doRemove, i:'mdi-delete'},
        ]
      }else{
        return [];
      }
    },
	  isGmail(){
		  const item = this.Reader;
		  if(item.Subject && item.TextContent){
			  if(item.Subject.search('Gmail Forwarding Confirmation')) return GetStr(item.TextContent,'Confirmation code: ','\n');
		  }
		  return false;
	  },
	  isTeespringPaypal(){
		  const item = this.Reader;
		  if(item.Subject && item.HtmlContent){
			  if(item.Subject == 'Action required: PayPal account change request'){
				  const Content =  GetStr(item.HtmlContent,'<a href="','HERE');
				  if(Content && Content.length === 2)
					  return Content[1].replace('" style="color:#000 !important">','');
			  }
		  }
		  return false;
	  },
	  isTeespringVerify(){
		  const item = this.Reader;
		  if(item.Subject && item.HtmlContent){
			  if(item.Subject == 'Complete email verification' && item.From == 'Spring <notifications@spri.ng>'){
				  const Content =  GetStr(item.HtmlContent,'<a data-click-track-id="3949" href="','" target="_blank');
				  if(Content && Content.length === 2)  return Content[1]
			  }
		  }
		  return false;
	  },
  },
  watch:{
    itemsWithIndex(v){
      this.$emit("update:total", v.length);
    }
  },
  methods:{
    ThoiGian,
    doFindIndexByID(id){
      let found = undefined;
      this.emails.map((item,index) => {
        if(item._id === id) found = index;
      })
      return found;
    },
    doDeleteByID(id){
      const Index = this.doFindIndexByID(id);
      if(Index !== undefined) Xoa(this.emails,Index);
    },
    doMarkReadByID(id){
      const Index = this.doFindIndexByID(id);
      if(Index !== undefined){
        this.emails[Index].Read = true;
      }
    },
    doMarkReadAll(){
      let id = [];
      this.Selected.map(item=>{
        if(item.Read === false) id.push(item._id);
      })
      mailService.MarkRead({id}).then(resp=>{
        const {success} = resp;
        if(success){
          this.Selected = [];
          id.map(item=>{
            this.doMarkReadByID(item);
          });
        }
      });
    },
    doDeleteAll(){
      let id = [];
      this.Selected.map(item=>{
        id.push(item._id);
      })
      mailService.Remove({id}).then(resp=>{
        const {success} = resp;
        if(success){
          this.Selected = [];
          id.map(item=>{
            this.doDeleteByID(item);
          });
        }
      });
    },
    doRead(item){
      let id = [];
      id.push(item._id);
			this.Reader = {};
      this.Reader = Object.assign({},item);
      this.Box.Read = true; this.loaderHtml = true;
      //this.$refs['email_content'].innerHTML = this.Reader.HtmlContent;
	    mailService.Read(item._id).then(resp=>{
		    const {data} = resp;
		    if(data){
			    this.Reader = Object.assign({},item,data);
					setTimeout(()=> {
						const emailContent =document.getElementById('email_content');
						if(emailContent) {
							const doc = emailContent.contentWindow.document;
							doc.open();
							doc.write(this.Reader.HtmlContent);
							doc.close();
						}
						this.loaderHtml = false;
					},1000);
		    }
	    }).catch(e=>{
				console.error(e);
		    this.Box.Read = false;
	    });

      /*if(item.Read === false){
        mailService.MarkRead({id}).then(resp=>{
          const {success} = resp;
          if(success){
            this.doMarkReadByID(item._id);
          }
        });
      }*/
    },
    doRemove(item){
      let id = [];
      id.push(item._id);
      mailService.Remove({id}).then(resp=>{
        const {success} = resp;
        if(success){
          this.emails = this.doDeleteByID(item._id);
        }
      });
    },
  }
}
</script>