<template>
  <div>
    <v-card flat>
      <v-card-title>
        <v-row>
          <v-col
            cols="auto"
          >
            <v-autocomplete
              v-model="Filter.Status"
              :items="FilterBy.Status"
              item-value="val"
              item-text="text"
              label="Lọc Email"
              @change="saveFilter"
            />
          </v-col>
          <v-spacer />
          <v-col
            cols="auto"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Tìm kiếm nhanh"
              single-line
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="itemsWithIndex"
          :loading="loader"
          :footer-props="{itemsPerPageText:'Dữ liệu mỗi trang','items-per-page-options':[50,100,150,200]}"
          :items-per-page="100"
        >
          <template #no-data>
            <br />
            <v-row>
              <v-col cols="12">
                <v-alert
                  border="right"
                  colored-border
                  type="warning"
                >
                  <span
                    class="orange--text text-center"
                  >
                    KHÔNG CÓ DỮ LIỆU
                  </span>
                </v-alert>
              </v-col>
            </v-row>
          </template>
          <template #[`item.Detail`]="{ item }">
            <template v-if="item.Used !== undefined && item.Used === getUser._id">
              <v-row>
                <v-col
                  cols="4"
                >
                  <v-list-item class="grow">
                    <v-list-item-content>
                      <v-list-item-subtitle>Email</v-list-item-subtitle>
                      <v-list-item-title>
                        <v-text-field
                          :value="item.Email"
                          label="Email"
                          readonly
                        />
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col
                  cols="4"
                >
                  <v-list-item class="grow">
                    <v-list-item-content>
                      <v-list-item-subtitle>Password</v-list-item-subtitle>
                      <v-list-item-title>
                        <v-text-field
                          :value="item.Password"
                          label="Password"
                          readonly
                        />
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col
                  cols="4"
                >
                  <v-list-item class="grow">
                    <v-list-item-content>
                      <v-list-item-subtitle>Mail khôi phục</v-list-item-subtitle>
                      <v-list-item-title>
                        <v-text-field
                          :value="item.Restore"
                          label="Mail khôi phục"
                          readonly
                        />
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col
                  cols="4"
                >
                  <v-list
                    subheader
                    one-line
                  >
                    <v-subheader inset>Ghi chú</v-subheader>
                    <v-list-item
                      v-for="(gc,gcIndex) in item.Notes"
                      :key="gcIndex"
                    >
                      <v-list-item-action>
                        <v-btn icon @click="doRemoveNote(item._id,gcIndex)" :disabled="Loader">
                          <v-icon color="red lighten-1">mdi-delete</v-icon>
                        </v-btn>
                      </v-list-item-action>
                      <v-list-item-content>
                        {{gc}}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-action>
                        <v-btn icon @click="doAddNotePopup(item)">
                          <v-icon color="green lighten-1">mdi-plus</v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <template v-if="item.Used !== getUser._id && item.Used !== undefined">
                <span class="text-h6 orange--text">Email đã được sử dụng</span>
              </template>
              <template v-else>
                <span class="text-h6 orange--text">Bấm sử dụng để lấy nội dung</span>
              </template>
            </template>
          </template>
          <template #[`item.controller`]="{ item }">
            <template v-if="item.controller !== undefined">
              <Controller
                :item="item"
                :controls="item.controller"
                :group="false"
              />
            </template>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="Box"
      max-width="600px"
      persistent
    >
      <Header
        :title="`Thêm ghi chú: ${noteData.Email}`"
        :close.sync="Box"
      >
        <v-form v-model="Valid">
          <v-card-text>
            <v-list-item class="grow">
                <v-list-item-content>
                  <v-list-item-subtitle>Nội dung</v-list-item-subtitle>
                  <v-list-item-title>
                    <v-text-field 
                      v-model="noteData.text"
                      :rules="[required('Nội dung')]"
                    />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="green"
              :disabled="!Valid"
              @click="doAddNote()"
            >
              Thêm
            </v-btn>
          </v-card-actions>
        </v-form>
        <loading :status="Loader" />
      </Header>
    </v-dialog>
  </div>
</template>

<script>
import Service from '@/modules/kho_email.js';
import { ThoiGian, GetStr, setStore, getStore, removeItem } from '@/helpers'
import { FindInArray } from '@/helpers/arrays.js';
import validations from '@/helpers/validations.js';
import {mapGetters} from 'vuex'
import Header from '@/components/base/pagehead.vue'
import Controller from '@/components/base/controller.vue'
import loading from '@/components/base/loading.vue'
export default {
  components:{
    Header,
    Controller,
    loading,
  },
  props: ['loader','accounts','doUpdate','doGetInstant'],
  data () {
    return {
      Valid:false,
      search: undefined,
      headers:[
        { text: 'Email', align: 'center', sortable: true,value: 'Email',width:"10%",class:"primary secondary--text text-center" },
        { text: 'Detail', align: 'center', sortable: true,value: 'Detail',width:"80%",class:"primary secondary--text text-center" },
        { text: 'Thao tác', align: 'center', sortable: false, value: 'controller',width:"10%",class:"primary secondary--text text-center" },
      ],
      autoLoader:undefined,
      noteData: {},
      Box:false,
      Loader:false,
      Filter:{
        Status:0,
      },
      FilterBy:{
        Status:[{'val':0,'text':'Tất cả'},{'val':1,'text':'Chưa sử dụng'},{'val':2,'text':'Đã sử dụng'}],
      },
    }
  },
  computed:{
    ...mapGetters(['getUser']),
    itemsWithIndex(){
      return this.accounts.map(
        (item) => {
          if(this.Filter.Status > 0){
            if(this.Filter.Status === 1 && item.Used) return undefined;
            if(this.Filter.Status === 2 && item.Used !== this.getUser._id) return undefined;
          }
          return {
            ...item,
            controller:this.controller(item)
          }
        }).filter(v=>!!v)
    },
  },
  methods:{
    ...validations,
    ThoiGian,
    saveFilter(){
      setStore('mail_kho_status',this.Filter.Status);
    },
    doRemoveNote(id,noteIndex){
      this.Loader = true;
      const index = FindInArray(this.accounts,id,'_id');
      let {Notes} = this.accounts[index];
      Notes.splice(noteIndex,1);
      this.accounts[index].Notes = Notes;
      this.$set(this.accounts[index],'Notes',Notes);
      this.$emit('update:accounts',this.accounts);
      this.noteData = {
        id,
        Notes,
      };
      Service.UpdateNote(this.noteData).then(resp=>{
        const {success} = resp;
        if(success) this.Box = false;
      }).finally(()=>{
        this.Loader = false;
      })
    },
    doAddNotePopup(item){
      const index = FindInArray(this.accounts,item._id,'_id');
      this.noteData = Object.assign({},{id:item._id,text:undefined,index,Email:item.Email});
      this.Box = true;
    },
    doAddNote(){
      this.Loader = true;
      const {index,text} = this.noteData;
      let {Notes} = this.accounts[index];
      Notes.push(text);
      // this.accounts[index].Notes = Notes;
      this.$set(this.accounts[index],'Notes',Notes);
      this.$emit('update:accounts',this.accounts);
      this.Loader = false;
      this.noteData.Notes = Notes;
      Service.UpdateNote(this.noteData).then(resp=>{
        const {success} = resp;
        if(success) this.Box = false;
      }).finally(()=>{
        this.Loader = false;
      })
    },
    controller(item){
      let controller = [
        {t:'Sử dụng',c:'blue',a:this.doGet, i:'mdi-download'},
        {t:'Cập nhật',c:'green',a:this.doUpdate, i:'mdi-pencil'},
      ];
      if(this.getUser.Access === 'admin'){
        
      }
      if(item.Used){
        controller.splice(0,1);
      }
      if(item.Used && item.Used !== this.getUser._id && this.getUser.Access !== 'Admin'){
        controller.splice(1,1);
      }
      return controller;
    },
    doGet(item){
      if(!item.Used){
        const id = item._id;
        Service.Used({id}).then(resp=>{
          const {success} = resp;
          if(success){
            this.doGetInstant(item._id,this.getUser._id);
          }
        });
      }
    }
  },
  mounted () {
    const filterStatus = getStore('mail_kho_status');
    if(filterStatus) this.Filter.Status = filterStatus;
  },
}
</script>

<style>

</style>