<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h6 pa-2">EMAIL</span>
        <v-btn
          class="pa-2"
          color="green"
          small
          @click="doAddBox"
        >
          Thêm Email
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-tabs
          v-model="tabs"
          fixed-tabs
          background-color="primary"
          dark
        >
          <v-tab
            class="secondary--text"
          >
            Check Mail ({{TotalInbox}})
          </v-tab>
          <v-tab
            class="secondary--text"
          >
            Kho Email ({{accounts.length}})
          </v-tab>
          <v-tab-item>
            <Inbox :total.sync="TotalInbox" :loader.sync="Loader.Inbox" :emails.sync="emails" />
          </v-tab-item>
          <v-tab-item>
            <Kho :loader.sync="Loader.Kho" :accounts.sync="accounts" :doUpdate="doUpdate" :doGetInstant="doGetByID" />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="Box"
      max-width="900px"
      persistent
    >
      <Header
        :title="!postData._id ? 'Thêm Email':`Cập nhật Email: ${postData.Email}`"
        :close.sync="Box"
      >
        <v-form v-model="FormAdd">
          <v-card-text>
            <v-row>
              <v-col
                cols="8"
              >
                <v-list-item class="grow">
                  <v-list-item-content>
                    <v-list-item-subtitle>Email</v-list-item-subtitle>
                    <v-list-item-title>
                      <v-text-field 
                        v-model="postData.Email"
                        :rules="[required('Email')]"
                      />
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col
                cols="4"
              >
                <v-list-item class="grow">
                  <v-list-item-content>
                    <v-list-item-subtitle>Password</v-list-item-subtitle>
                    <v-list-item-title>
                      <v-text-field 
                        v-model="postData.Password"
                        :rules="[required('Password')]"
                      />
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col
                cols="8"
              >
                <v-list-item class="grow">
                  <v-list-item-content>
                    <v-list-item-subtitle>Mail khôi phục</v-list-item-subtitle>
                    <v-list-item-title>
                      <v-text-field
                        v-model="postData.Restore"
                        :rules="[required('Mail khôi phục')]"
                      />
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="green"
              v-if="!postData._id"
              :disabled="!FormAdd"
              @click="doAdd2Kho"
            >
              Thêm
            </v-btn>
            <v-btn
              color="green"
              v-if="postData._id"
              :disabled="!FormAdd"
              @click="doUpdate2Kho"
            >
              Lưu
            </v-btn>
          </v-card-actions>
        </v-form>
        <loading :status="Loader.Add" />
      </Header>
    </v-dialog>
  </div>
</template>

<script>
import mailService from '@/modules/mail.js';
import kmailService from '@/modules/kho_email.js';
import Inbox from './mail/Inbox.vue';
import Kho from './mail/Kho.vue';
import Header from '@/components/base/pagehead.vue';
import validations from '@/helpers/validations.js';
import loading from '@/components/base/loading.vue';
import {setStore,getStore} from '@/helpers/storage.js'
export default {
  components:{
    Inbox,
    Kho,
    Header,
    loading,
  },
  data () {
    return {
      tabs:0,
      TotalInbox:0,
      TotalKho:0,
      Box:false,
      postData:{},
      FormAdd: false,
      emails:[],
      accounts: [],
      UpdateInbox:false,
      UpdateKho:false,
      autoLoaderInbox:undefined,
      autoLoaderKho:undefined,
      Loader:{
        Inbox:false,
        Kho:false,
        Add:false,
      },
    }
  },
  watch:{
    UpdateInbox(v){
      if(v === true){
        this.UpdateInbox = false;
        this.AutoLoader();
      }
    },
    tabs(v){
      setStore('mailsystem',v);
    }
  },
  methods:{
    ...validations,
    doAddBox(){
      this.postData = {};
      this.Box = true;
    },
    doUpdate(item){
      this.postData = Object.assign({},item);
      this.Box = true;
    },
    doFindIndexByIDKho(id){
      let found = undefined;
      this.accounts.map((item,index) => {
        if(item._id === id) found = index;
      })
      return found;
    },
    doGetByID(id,User){
      const Index = this.doFindIndexByIDKho(id);
      if(Index !== undefined){
        // this.accounts[Index].Used = User;
        this.$set(this.accounts[Index],'Used',User);
      }
    },
    doAdd2Kho(){
      this.Loader.Add = true;
      const {Email,Password,Restore} = this.postData;
      kmailService.Add({Email,Password,Restore}).then(resp=>{
        const {success} = resp;
        if(success){
          this.Box = false;
          this.LoadKho();
        }
      }).finally(()=>{
        this.Loader.Add = false;
      })
    },
    doUpdate2Kho(){
      this.Loader.Add = true;
      const {_id,Email,Password,Restore} = this.postData;
      kmailService.Update({_id,Email,Password,Restore}).then(resp=>{
        const {success} = resp;
        if(success){
          this.Box = false;
          this.LoadKho();
        }
      }).finally(()=>{
        this.Loader.Add = false;
      })
    },
    AutoLoaderKho(){
      return kmailService.GetAll().then(resp=>{
        this.accounts = resp.data;
      })
    },
    AutoLoaderInbox(){
      return mailService.GetAll().then(resp=>{
        this.emails = resp.data;
      })
    },
    LoadKho(){
      this.Loader.Kho = true;
      this.AutoLoaderKho().finally(()=>{
        this.Loader.Kho = false;
      });
    },
    LoadInbox(){
      this.Loader.Inbox = true;
      this.AutoLoaderInbox().finally(()=>{
        this.Loader.Inbox = false;
      });
    },
  },
  mounted () {
    this.tabs = getStore('mailsystem') || 0;
    this.LoadInbox();
    this.LoadKho();
    this.autoLoaderInbox = setInterval(this.AutoLoaderInbox,10000);
    this.autoLoaderKho = setInterval(this.AutoLoaderKho,10000);
  },
  beforeDestroy(){
    if(this.autoLoaderInbox) clearInterval(this.autoLoaderInbox);
    this.autoLoaderInbox = undefined;
    if(this.autoLoaderKho) clearInterval(this.autoLoaderKho);
    this.autoLoaderKho = undefined;
  }
}
</script>

<style>

</style>