import { ResponseData, ResponseError, axiosInstance, Request } from '@/helpers'
const moduleName = 'kho_email'
export const GetAll = () =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}`,req).then(ResponseData).catch(ResponseError)
}
export const Add = ({Email,Password,Restore}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/add`,{Email,Password,Restore},req).then(ResponseData).catch(ResponseError)
}
export const Used = ({id}) =>{
  const req = Request();
  return axiosInstance.get(`/${moduleName}/use/${id}`,req).then(ResponseData).catch(ResponseError)
}
export const Update = ({_id,Email,Password,Restore}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/update/${_id}`,{Email,Password,Restore},req).then(ResponseData).catch(ResponseError)
}
export const UpdateNote = ({id,Notes}) =>{
  const req = Request();
  return axiosInstance.post(`/${moduleName}/note/${id}`,{Notes},req).then(ResponseData).catch(ResponseError)
}
export default {
  GetAll,
  Add,
  Used,
  Update,
  UpdateNote,
}